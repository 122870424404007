@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";

@font-face {
	font-family: 'La Luxes Serif';
	src: url('../../dist/font/LaLuxesSerif.woff2') format('woff2'),
			url('../../dist/font/LaLuxesSerif.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Snell Roundhand';
	src: url('../../dist/font/SnellRoundhand-Black.woff2') format('woff2'),
			url('../../dist/font/SnellRoundhand-Black.woff') format('woff');
	font-weight: 900;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Snell Roundhand';
	src: url('../../dist/font/SnellRoundhand.woff2') format('woff2'),
			url('../../dist/font/SnellRoundhand.woff') format('woff');
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Snell Roundhand';
	src: url('../../dist/font/SnellRoundhand-Bold.woff2') format('woff2'),
			url('../../dist/font/SnellRoundhand-Bold.woff') format('woff');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

html {
	background: #100000;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	font-size: 16px;
	line-height: 1;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

.desktop{
	@include tablet-down {
		display: none !important;
	}
}

.mobile{
	@include tablet-up {
		display: none !important;
	}
}

body {
	color: $color-white;
}

a {
	color: $color-white;

	@include hover-focus {
		color: $color-white;
	}
}

img{
	display: block;
	margin: 0;
}

.glow{
	img{
		scale: 3.9;
		translate: 0 -5px;
	}
	&.mobile{
    position: relative;
		top: -60px;
	}
}

header{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 9;
	background-color: #80070f;
	padding: 10px 0;
	p{
		text-align: center;
		font-family: 'La Luxes Serif';
		margin: 0;
	}
}

main {
	padding-top: 60px;
	overflow: hidden;
	.content{
		position: absolute;
		z-index: 1;
		width: 100%;
		.container{
			overflow: hidden;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			aspect-ratio: 2/2.5;
			img{
				width: 100%;
				&.logo{
					max-width: 50vw;
				}
				&.cover{
					max-width: 100%;
				}
				&.heading{
					max-width: 70vw;
				}
				&.copy{
					max-width: 60vw;
					@include tablet-down{
						max-width: 90vw;
					}
				}
			}
			.btn{
				background-color: #80070f;
				padding: 15px 30px;
				display: block;
				text-align: center;
				width: 100%;
				max-width: max-content;
				margin: 10px auto 0;
				font-family: "sanvito-pro", sans-serif;
				font-family: 'La Luxes Serif';
				font-weight: 300;
				font-size: 2rem;
				img{
					width: 100%;
					max-width: 205px;
					margin: 0 auto;
					height: auto;
				}
			}
		}
	}
	@include tablet-up{
		display: flex;
		justify-content: flex-end;
		padding-top: 0;
		.content{
			max-width: 50vw;
			.container{
				padding-top: 40px;
				aspect-ratio: unset;
				max-height: 100vh;
				img{
					object-fit: contain;
					&.logo{
						max-width: 289px;
						max-height: 16vh;
					}
					&.cover{
						max-width: 646px;
						max-height: 40vh;

					}
					&.heading{
						max-width: 497px;
						max-height: 13vh;
					}
					&.copy{
						max-width: 449px;
						max-height: 10vh;
					}
				}
			}
		}
	}

	.popup{
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.8);
		z-index: 999;
		pointer-events: none;
		opacity: 0;
		transition: all .4s;
		&.active{
			pointer-events: all;
			opacity: 1;
			.container{
				.wrapper{
					.popup-content{
						top: 0;
					}
				}
			}
		}
		.container{
			min-height: 100vh;
			display: flex;
			flex-direction: column;
			justify-content: center;
			.wrapper{
				.popup-content{
					position: relative;
					top: 100px;
					transition: all .8s;
					width: 100%;
					max-width: 800px;
					margin: 0 auto;
					position: relative;
					@include tablet-down{
						max-width: 350px;
					}
					img{
						position: relative;
						width: 100%;
						height: auto;			
					}
					.popup-content-wrapper{
						position: absolute;
						height: 100%;
						top: 0;
						left: 0;
						width: 50%;
						text-align: center;
						font-family: "sanvito-pro", sans-serif;
						font-family: 'La Luxes Serif';
						display: flex;
						flex-direction: column;
						align-items: center;
						gap: 30px;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						padding: 35px;
						@include tablet-down{
							gap: 15px;
							width: 100%;
							height: 50%;
						}
						h3{
							font-weight: 300;
							font-size: 2rem;
							margin: 0;
							line-height: 1.2;
							@include tablet-down{
								font-size: 2rem;
							}
						}
						.btn{
							font-size: 1.5rem;
							font-weight: 300;
							padding: 10px 20px;
							border: solid 1px #000;
							color: #000;
							display: block;
							background: linear-gradient(90deg, #fddda9 0%, #ecb556 100%);					
							@include tablet-down{
								font-size: 1.5rem;
							}		
						}
					}
					button{
						position: absolute;
						top: 10px;
						right: 10px;
						z-index: 99;
						padding: 0;
						line-height: 1;
						font-size: 30px;
						width: 30px;
						height: 30px;
						display: flex;
						justify-content: center;
						align-items: center;
						background-color: transparent;
						border: none;
						color: #fff;
					}
				}
			}
		}
	}
}

footer{
	font-size: 12px;
	padding: 20px 0;
	text-align: center;
	p{
		&:last-child{
			margin-bottom: 0;
		}
	}
}